import {Dispatch, useReducer} from 'react';

export type AccordionToggleMode = 'single' | 'multi';

type State = {
    open: Map<string, boolean>;
    toggleMode: AccordionToggleMode;
};

type ToggleActionKind = 'TOGGLE';
type ModeActionKind = 'SET_TOGGLE_MODE';

type ToggleAction = {
    type: ToggleActionKind;
    payload: {
        key: string;
        value: boolean;
    };
};

type ModeAction = {
    type: ModeActionKind;
    payload: AccordionToggleMode;
};

type Action = ToggleAction | ModeAction;

const INITIAL_STATE: State = {
    open: new Map(),
    toggleMode: 'multi',
};

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'SET_TOGGLE_MODE': {
            return {
                ...state,
                toggleMode: action.payload,
            };
        }
        case 'TOGGLE': {
            const isMulti = state.toggleMode === 'multi';
            let newMap: Map<string, boolean>;
            if (isMulti) {
                newMap = state.open;
                newMap.set(action.payload.key, action.payload.value);
            } else {
                newMap = new Map();
                newMap.set(action.payload.key, action.payload.value);
            }
            return {
                ...state,
                open: newMap,
            };
        }
        default:
            return state;
    }
};

export type AccordionContextType = {
    state: State;
    dispatch: Dispatch<Action>;
};

const useAccordion = () => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    return {state, dispatch};
};

export default useAccordion;
